import React from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string; // using as id
  comment?: string;
  label?: string;
  inputContainerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export default function Input({ label, name, comment, inputContainerProps, ...props }: InputProps) {
  const { getFieldProps, getFieldMeta } = useFormikContext();
  const field = getFieldProps(name);
  const meta = getFieldMeta(name);
  return (
    <Form.Group className="text-left" controlId={name} {...inputContainerProps}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control {...(props as unknown)} {...field} isInvalid={!!meta.error} />
      {comment && <div className="small text-muted">{comment}</div>}
      {meta.touched && meta.error ? <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback> : null}
    </Form.Group>
  );
}
